<template>
<div>
  <div class="vx-row mt-base">
    <div class="vx-col flex flex-wrap items-center w-full md:w-3/5">
      <div class="w-full">
        <slot name="before-msg"></slot>
      </div>

      <div class="w-full">
        <sms-text-box
          ref="smsTextBox"
          v-model="messageLocal"
          :placeholder="smsTextBoxPlaceholder"
          :attributes-from-contacts="attributesFromContactsLocal"
          :has-interpolations.sync="hasInterpolationsLocal"
          :interpolations.sync="interpolationsLocal"
          :message-type="messageType"
          :message-length-offset="messageOffset"
          @add-template="showTemplates=true"
          @state-change="(val) => smsTextBoxIsValid=val"
          @missing-review-link="showMissingReviewLinkDialog()"
          @review-link-on-marketing="activePromptWrongReviewLink=true">
        </sms-text-box>

<!--        <shorter-url-->
<!--          class="mt-5"-->
<!--          @insert="insertShorterUrl"/>-->

        <insert-template
          class="mt-5"
          @insert="insertTemplate"/>

        <vs-alert
          color="warning"
          class="mt-base"
          iconPack="feather"
          icon="icon-alert-circle"
          :title="$t('$TextCampaignsModules.TextMessageExceededLimitTitle' )"
          :active="hasInterpolationsLocal">
          {{ $t('$TextCampaignsModules.TextMessageExceededLimitMsg' ) }}
        </vs-alert>
      </div>

      <div class="w-full">
        <slot name="after-msg"></slot>
      </div>
    </div>
    <div class="vx-col hidden md:block md:w-2/5">
      <message-preview
        ref="messagePreview"
        :message="messageLocal"
        :message-type="messageType"
        :interpolations="interpolationsLocal"
        :attributes-from-contacts="attributesFromContactsLocal"
        :caller-id-type="callerIdType"
        :sender-id="senderId"
        :sender-business-name="senderBusinessName"
        @test="(phoneNumber) => $emit('test', phoneNumber)"
        @opt-out-message-length="(length) => optOutMessageLength = length"/>
    </div>
  </div>

  <vs-prompt
    color="warning"
    :title="$t(`${invalidTypeForReviewLinkNotifyKey}.Title`)"
    :acceptText="$t(`${invalidTypeForReviewLinkNotifyKey}.AcceptText`)"
    :cancelText="activePromptWrongReviewLinkCancelText"
    buttonAccept="accept-fill"
    buttonCancel="cancel-flat"
    @accept="changeToTransactional()"
    @cancel="onDeleteReviewLink()"
    :active.sync="activePromptWrongReviewLink">
    <div class="con-exemple-prompt">
      <p v-html="$t(`${invalidTypeForReviewLinkNotifyKey}.Message`)">
      </p>

      <p class="mt-5">{{ $t(`${invalidTypeForReviewLinkNotifyKey}.Footer`) }}</p>
    </div>
  </vs-prompt>

  <vs-popup
    :title="$t('$Components.$GoogleReviewsSummaryCard.SetupGoogleReviews')"
    fullscreen
    :active.sync="activeModalReviewSettings"
    class="mt-0 mb-0 pt-0 pb-0">
    <transition name="zoom-fade">
      <reviews-settings-create-or-edit
        v-if="activeModalReviewSettings"
        :card-style="false"
        hide-request-review-button
        @cancel="activeModalReviewSettings=false"/>
    </transition>
  </vs-popup>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import enums from '@/enums';

// Components
import SMSTextBox from '@/views/modules/_components/SMSTextBox.vue';
import SMSCampaignListCreateOrEditMessagePreview
from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessagePreview.vue';
// import SMSCampaignListCreateOrEditMessageShorterUrl
//   from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessageShorterUrl.vue';
import SMSCampaignListCreateOrEditMessageInsertTemplate
from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessageInsertTemplate.vue';
import ReviewsSettingsCreateOrEdit from '@/views/modules/reviews/ReviewsSettingsCreateOrEdit.vue';

/**
 * Component to enter message of sms Campaign
 *
 * @module views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessage
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} message - message of campaign
 * @vue-prop {boolean} hasInterpolations - indicate if campaign has interpolations
 * @vue-prop {Object[]} interpolations - message interpolations
 * @vue-prop {string} messageType - message type of campaign
 * @vue-prop {string} callerIdType - caller id type of campaign
 * @vue-prop {Object | string} senderId - sender id of campaign
 * @vue-prop {Object[]} attributesFromContacts - attributes of contacts
 * @vue-data {Object[]} attributesFromContactsLocal - attributes from contacts
 * @vue-data {string} messageLocal - local message of campaign
 * @vue-data {boolean} hasInterpolationsLocal - indicate if campaign has local interpolations
 * @vue-data {Object[]} interpolationsLocal - message interpolations local
 * @vue-data {number} optOutMessageLength - length of opt out message
 * @vue-data {boolean} smsTextBoxIsValid - indicate if sms tex box is valid or no
 * @vue-data {boolean} activeModalReviewSettings - indicate if show the review settings modal
 * @vue-data {boolean} activePromptWrongReviewLink - indicate if show the wrong review link prompt
 * @vue-computed {number} messageOffset - message offset length to apply
 * @vue-computed {string} smsTextBoxPlaceholder - placeholder to sms tex box
 * @vue-computed {boolean} interpolationsHaveReviewLink - indicate if interpolations have reviewLink
 * @vue-computed {string} activePromptWrongReviewLinkCancelText -
 * cancel text for wrong review link prompt
 * @vue-event {void} fetchContactsAttributes - fetch contacts attributes
 * @vue-event {void} insertTemplate - insert one template
 * @vue-event {void} insertShorterUrl - insert one shorter URL
 * @vue-event {void} onDeleteReviewLink - called to delete review link from message
 * @vue-event {void} changeToTransactional - called to back step
 * @vue-event {void} showMissingReviewLinkDialog - show missing review link dialog
 * @vue-event {void} validateMessage - validate the message
 */
export default {
  name: 'SMSCampaignListCreateOrEditMessage',
  i18n: {
    messages: {
      en: {
        $MissingReviewLinkNotify: {
          Title: 'You haven\'t set up your reviews',
          Message: 'You must set up your review configuration to start send transactional campaigns with your review link.',
          AcceptText: 'Set up',
          CancelText: 'Cancel',
        },
        $InvalidCampaignTypeForReviewLinkNotify: {
          Title: 'You can\'t request reviews in marketing campaigns',
          Message: 'It’s against <a class="link-contextual" href="https://support.google.com/contributionpolicy/answer/7411351#zippy=%2Cphotos-and-videos%2Ctext-reviews-and-captions" target="_blank"> Google review policies</a> to solicit reviews from customers that included promotional or commercial content.',
          Footer: 'Do you want to send a reviews campaign instead?',
          AcceptText: 'Change to review campaign',
          CancelText: 'Cancel',
          DeleteReviewLink: 'Delete the reviews link',
        },
        $InvalidMessageTypeForReviewLinkNotify: {
          Title: 'You can\'t request reviews in marketing messages',
          Message: 'It’s against <a class="link-contextual" href="https://support.google.com/contributionpolicy/answer/7411351#zippy=%2Cphotos-and-videos%2Ctext-reviews-and-captions" target="_blank"> Google review policies</a> to solicit reviews from customers that included promotional or commercial content.',
          Footer: 'Do you want to send a review message instead?',
          AcceptText: 'Change to review message',
          CancelText: 'Cancel',
          DeleteReviewLink: 'Delete the reviews link',
        },
      },
    },
  },
  components: {
    SmsTextBox: SMSTextBox,
    messagePreview: SMSCampaignListCreateOrEditMessagePreview,
    // shorterUrl: SMSCampaignListCreateOrEditMessageShorterUrl,
    insertTemplate: SMSCampaignListCreateOrEditMessageInsertTemplate,
    ReviewsSettingsCreateOrEdit,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    hasInterpolations: {
      type: Boolean,
      required: false,
      default: false,
    },
    interpolations: {
      type: Array,
      required: false,
      default() {
        return [];
      },
      validator(interpolations) {
        return interpolations.every((interpolation) => typeof interpolation === 'object'
          && interpolation !== null
          && 'shorthand' in interpolation
          && typeof interpolation.shorthand === 'string'
          && 'attribute' in interpolation
          && typeof interpolation.attribute === 'string');
      },
    },
    messageType: {
      type: String,
      required: false,
      validator(type) {
        if (type === '') return true;
        return Object.values(enums.Campaign.MessageType).includes(type);
      },
    },
    callerIdType: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.Sender.Caller.Type).includes(type);
      },
    },
    senderId: {
      type: [Object, String],
      required: true,
    },
    senderBusinessName: {
      type: String,
      required: true,
    },
    attributesFromContacts: {
      type: Array,
      required: false,
    },
    isQuick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      attributesFromContactsLocal: [],
      messageLocal: this.message,
      hasInterpolationsLocal: this.hasInterpolations,
      interpolationsLocal: this.interpolations,
      optOutMessageLength: 0,
      smsTextBoxIsValid: false,
      activeModalReviewSettings: false,
      activePromptWrongReviewLink: false,
    };
  },
  computed: {
    ...mapGetters({
      shortReviewLink: 'auth/tenantShortReviewLink',
    }),
    messageOffset() {
      return this.messageType === this.$enums.Campaign.MessageType.MARKETING
        ? this.optOutMessageLength
        : 0;
    },
    smsTextBoxPlaceholder() {
      switch (this.messageType) {
        case enums.Campaign.MessageType.MARKETING:
        case enums.Campaign.MessageType.TRANSACTIONAL:
          return '';

        default:
          return '';
      }
    },
    interpolationsHaveReviewLink() {
      return this.interpolationsLocal.some(
        (interpolation) => interpolation.type === 'special'
          && interpolation.attribute === this.$enums.Campaign.SpecialPlaceholders.REVIEW_LINK,
      );
    },
    activePromptWrongReviewLinkCancelText() {
      return this.interpolationsHaveReviewLink
        ? this.$t(`${this.invalidTypeForReviewLinkNotifyKey}.DeleteReviewLink`)
        : this.$t(`${this.invalidTypeForReviewLinkNotifyKey}.CancelText`);
    },
    invalidTypeForReviewLinkNotifyKey() {
      return this.isQuick
        ? '$InvalidMessageTypeForReviewLinkNotify'
        : '$InvalidCampaignTypeForReviewLinkNotify';
    },
  },
  watch: {
    attributesFromContacts() {
      this.attributesFromContactsLocal = this.attributesFromContacts;
    },
    messageLocal() {
      this.$emit('update:message', this.messageLocal);
    },
    hasInterpolationsLocal() {
      this.$emit('update:has-interpolations', this.hasInterpolationsLocal);
    },
    interpolationsLocal() {
      this.$emit('update:interpolations', this.interpolationsLocal);
    },
    smsTextBoxIsValid(val) {
      this.$emit('validate', val);
    },
  },
  created() {
    if (!this.attributesFromContacts) {
      this.fetchContactsAttributes();
    } else {
      this.attributesFromContactsLocal = this.attributesFromContacts;
    }
  },
  mounted() {
    this.optOutMessageLength = this.$refs.messagePreview.optOutMessage.length;
  },
  methods: {
    ...mapActions({
      fetchAllAttributes: 'attribute/fetchAllAttributes',
    }),
    async fetchContactsAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.attributesFromContactsLocal = resp.data;
    },
    insertTemplate(template) {
      this.$refs.smsTextBox.insertText(template);
      this.validateMessage();
    },
    insertShorterUrl(url) {
      this.$refs.smsTextBox.insertText(url);
    },
    onDeleteReviewLink() {
      if (this.interpolationsHaveReviewLink) {
        this.$refs.smsTextBox.deleteInterpolation(
          this.$enums.Campaign.SpecialPlaceholders.REVIEW_LINK,
        );
      }
    },
    changeToTransactional() {
      this.activePromptWrongReviewLink = false;

      setTimeout(() => {
        this.$emit('change-to-transactional');
      });
    },
    showMissingReviewLinkDialog() {
      this.$showConfirmWarningDialog({
        title: this.$t('$MissingReviewLinkNotify.Title'),
        text: this.$t('$MissingReviewLinkNotify.Message'),
        acceptText: this.$t('$MissingReviewLinkNotify.AcceptText'),
        cancelText: this.$t('$MissingReviewLinkNotify.CancelText'),
        accept: () => {
          this.activeModalReviewSettings = true;
        },
      });
    },
    async validateMessage() {
      let valid = true;
      await this.$refs.smsTextBox.$validator.validateAll();
      valid = this.$refs.smsTextBox.isValid;

      if (this.messageType === this.$enums.Campaign.MessageType.TRANSACTIONAL) {
        if (this.hasInterpolationsLocal
          && this.interpolationsHaveReviewLink
          && !this.shortReviewLink) {
          valid = false;
          this.showMissingReviewLinkDialog();
        }
      } else if (this.messageType === this.$enums.Campaign.MessageType.MARKETING) {
        if (this.hasInterpolationsLocal && this.interpolationsHaveReviewLink) {
          valid = false;
          this.activePromptWrongReviewLink = true;
        }
      }

      return valid;
    },
  },
};
</script>
